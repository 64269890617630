body {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.5;

  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: $wideScreenBreakpoint) {
    min-width: 900px;
    padding: 0 5vw;

    > header, > footer {
      margin: 0 -5vw;
    }
  }
  > header {
    flex-shrink: 0;
    flex-grow: 0;
  }
  > main {
    flex: 1;
  }
  > footer {
    flex-shrink: 0;
  }
}

body > header {
  background: $primaryColor;
  color: $primaryColorInverse;
  font-size: 80%;

  padding-left: 3rem;

  @media screen and (min-width: $wideScreenBreakpoint) {
    padding-left: 5vw;
  }

  > :first-child {
    margin-left: -#{$logoSide / 1.5};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  a:link, a:hover, a:visited {
    color: inherit;
  }

  h1 {
    font-size: 100%;
    text-transform: uppercase;
    letter-spacing: -.025em;
  }
  h2 {
    font-size: 110%;
    font-weight: normal;
    padding-left: 2em;
    position: relative;
    overflow: hidden;

    // Arrow
    &:before {
      display: block;
      position: absolute;
      content: " ";
      border: 1px solid white;
      left: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translateX(-50%) translateY(-50%) scaleY(2) rotate(45deg);
    }
  }
  .logo, .logo-inverse {
    display: none;
    width: $logoSide / 1.5;
    height: $logoSide / 1.5;
    margin-right: 0;
  }
  .logo {
    display: none;
  }
}

body > main {
  padding: 0 2rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  @media screen and (min-width: $wideScreenBreakpoint) {
    padding-left: 0;
    padding-right: 0;
  }
}

body > footer {
  margin-top: 1rem;

  padding: 2rem 2rem;

  text-align: right;
  color: #333;
  background: whiteSmoke;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: $wideScreenBreakpoint) {
    padding-left: 5vw;
    padding-right: 5vw;
    flex-flow: row nowrap;
  }

  .footer-pill {
    white-space: nowrap;
    height: 24px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    padding: 1rem 2rem;
    margin: 0 1rem 0 0;
    background: whiteSmoke;
    border-radius: 2rem;
    color: #a6a6a6;

    .icon {
      margin: -1rem .5rem -1rem -2rem;
      padding-left: 2rem;
      width: 1rem;
    }

    &.interactive {
      transition: color .2s linear, background .2s linear;

      &:hover {
        background: #a6a6a6;
        color: white;
      }
    }
  }

  .powered-by {
    padding-right: 1rem;
    background: transparent;

    .icon {
      padding-left: .5rem;
      margin-right: 2rem;

      img.logo {
        filter: grayscale(100%);
        opacity: .35;
        transition: opacity .2s linear;
        width: 2.5rem;
        height: 2.5rem;
        display: block;
      }
    }

    &.interactive:hover {
      color: unset;
      background: transparent;

      .logo {
        opacity: 1;
      }
    }
  }
}

a {
  &:link, &:visited, &:hover {
    color: $linkColor;
    text-decoration: none;
  }
}

article.service-publication {
  .title {
    margin-bottom: .3em;
    font-weight: normal;
  }
}
