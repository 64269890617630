body.layout--message_index-by-pub-amended {
  article.message > header > .title {
    .label {
      font-size: 90%;
      margin-top: 0;

      .fas {
        font-size: 100%;
        margin-right: .5rem;
      }
    }
    .anchor {
      font-size: 90%;
      margin-top: 0;
      padding-top: 0;
    }
  }
}

body.message_index {
  > main {
    > header {
      margin: 0 -2rem;
      padding: 0 2rem;

      align-self: stretch;
      margin-bottom: 1rem;
      background: whiteSmoke;

      @media screen and (min-width: $wideScreenBreakpoint) {
        padding-left: 5vw;
        padding-right: 5vw;
        margin-left: -5vw;
        margin-right: -5vw;
      }

      .index-type {
        @extend .section-title;
      }

      .meta, .yearly-archive {
        .far, .fas {
          width: 2rem;
          margin-left: -2rem;
          text-align: center;
          display: inline-block;
        }
      }

      .meta-row {
        display: flex;
        flex-flow: row wrap;
        margin: 1em 0;

        > * {
          margin: 0;
          margin-right: 3rem; // Compensate for negative margin from the icon
        }
      }
    }

    > table {
      > tbody, > thead {
        &, & > tr {
          > td, > th {
            padding: .5em 0;
          }
        }
      }
      td.message {
        width: 100%;
      }
      td.date {
        padding-right: 2rem;
        padding-top: 1.8em;

        .publication-date {
          white-space: nowrap;
        }
      }
    }
  }
}
