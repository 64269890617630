body.home {
  font-size: 100%;

  > header {
    padding-top: 6vh;
    padding-left: 3.5rem;
    margin: 0;
    margin-bottom: 1em;

    background: transparent;
    color: $textColor;

    @media screen and (min-width: $wideScreenBreakpoint) {
      padding-left: 2rem;
    }

    h1 {
      .publication-name {
        color: $primaryColor;
        font-weight: bold;
        font-size: 140%;
        margin-right: .5rem;
      }
    }

    .logo-inverse {
      display: none;
    }
    .logo {
      display: none;
      width: $logoSide;
      height: $logoSide;
      margin: 0;
    }
  }

  > main {
    @media screen and (min-width: $wideScreenBreakpoint) {
      padding-left: 12px;
    }

    .actionable-row {
      max-width: 70vw;
      display: flex;
      flex-flow: row wrap;
      margin: .5rem 0 1rem 0;

      @media screen and (min-width: $wideScreenBreakpoint) {
        margin: 1em 0;
        flex-flow: row nowrap;
      }

      > * {
        margin: 0;
        margin-right: 1rem;
        white-space: nowrap;
      }
    }

    .actionable {
      font-size: 90%;
      line-height: 1.2;
      padding-left: 2rem;

      .far, .fas {
        width: 2rem;
        margin-left: -2rem;
        text-align: center;
      }

      &.annex-info {
        a {
          white-space: nowrap;
        }
      }
      &.online-link {
        white-space: nowrap;
      }
    }

    .ob-issue {
      > header {
        .issue-title, .cutoff-date {
          display: inline-block;
          vertical-align: middle;
          margin: 0;
        }
        .cutoff-date {
          margin-left: 1em;
        }
      }
      .lang-links {
        .lang {
          &:after {
            content: ", ";
          }
          &:last-child:after {
            content: "";
          }
        }
      }
    }

    > section {
      &.about {
        background: whiteSmoke;
        padding: 1rem 1rem 0 1rem;
        margin: 1rem 0 1.5rem -#{$borderRadius};
        border-radius: $borderRadius;

        @include withExpandableToggle(1rem, $borderRadius);

        .included-contents {
          padding-top: 1rem;
        }

        &.expanded > .toggle:hover .indicator {
          transform: scale(1, 0) rotate(0deg);
        }

        p {
          margin: 0 0 1rem 0;
        }
      }

      &.browse-by-recommendation {
        > .items {
          > .recommendation {
            display: inline-block;
            margin-right: 1em;

            .title {
              margin-top: 0;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  > footer {
    background: transparent;
  }
}
