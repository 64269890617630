@import 'normalize';

$primaryColor: #1aa1da;
$primaryColorInverse: white;
$accentColor: #DF314E;
$accentColorInverse: white;
$textColor: black;
$linkColor: darken($primaryColor, 5);

$logoSide: 60px;
$logoTextOffset: $logoSide / 5;
$borderRadius: $logoTextOffset / 2;

$wideScreenBreakpoint: 800px;

@import 'base';
@import 'main';
@import 'home';
@import 'message_index';
@import 'issue_page';
@import 'docs';
